/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import slugify from 'slugify'
import parse from 'html-react-parser'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel'
import SEO from '../components/Seo'
import 'pure-react-carousel/dist/react-carousel.es.css'

const StyledWrapper = styled.div`
  max-width: 1100px;
  padding: 45px 0 78px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`

const PathContainer = styled.div`
  width: 318px;
  height: 413px;
  box-shadow: 0px 4px 8px rgb(0 8 32 / 6%), 0px 1px 4px rgb(0 8 32 / 8%);
  border-radius: 4px;
  margin: 5px 20px 5px 5px;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    h2 {
      text-decoration: underline;
    }
  }
`

const PathContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  height: 183px;
`

const PathFooter = styled.div`
  padding: 20px;
  border-top: 1px solid #dae1ed;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #556685;
  svg {
    margin-right: 6px;
  }
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const PathTitle = styled.h2`
  margin: 0px;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  color: #152540;
`

const PathDescripption = styled.p`
  margin: 0;
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #556685;
  overflow: hidden;
  max-height: 80px;
  display: block;

  strong {
    font-weight: 400;
  }
  p {
    display: none;
    &:first-child {
      display: block;
    }
  }
`
const PathTag = styled.span`
  width: auto;
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #67c23a;
  background-color: rgba(103, 194, 58, 0.1);
  padding: 5px 10px;
  margin-top: 10px;
  border-radius: 2px;
`

const SectionHeader = styled.h1`
  font-weight: 800;
  font-size: 32px;
  line-height: 52px;
  margin-bottom: 11px;
`

const StyledButtonBack = styled(ButtonBack)`
  position: absolute;
  width: 38px !important;
  height: 38px !important;
  background: #f9f8fc !important;
  border: none;
  opacity: 1 !important;
  color: #5a55ab !important;
  top: -60px;
  right: 50px;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f8fc !important;
`
const StyledButtonNext = styled(ButtonNext)`
  position: absolute;
  width: 38px !important;
  height: 38px !important;
  background: #f9f8fc !important;
  border: none;
  opacity: 1 !important;
  color: #5a55ab !important;
  top: -60px;
  right: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f8fc !important;
`

const PathsList = ({ pageContext: { data } }) => {
  // Initialize state with undefined width so server and client renders match
  const [slides, setSlides] = useState({
    visibleSlides: undefined,
    naturalSlideHeight: 20,
  })

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 519) {
        setSlides({
          visibleSlides: 1,
          naturalSlideHeight: 20,
        })
      }
      if (window.innerWidth > 519 && window.innerWidth < 789) {
        setSlides({
          visibleSlides: 1.6,
          naturalSlideHeight: 24,
        })
      }
      if (window.innerWidth > 789 && window.innerWidth > 990) {
        setSlides({
          visibleSlides: 2.4,
          naturalSlideHeight: 24,
        })
      }
      if (window.innerWidth > 1024) {
        setSlides({
          visibleSlides: 3.2,
          naturalSlideHeight: 25,
        })
      }
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return (
    <StyledWrapper>
      <SEO
        title="Learning paths - Innential"
        description="Innential helps teams reach their goals by providing easy access to knowledge and learning."
      />
      {Object.entries(data).map((item) => {
        if (item[1].length > 0 && item[0] !== 'Kompetenzen') {
          return (
            <section key={item[0]} style={{ paddingTop: `80px` }}>
              <SectionHeader>{item[0]}</SectionHeader>
              <CarouselProvider
                changeVisableSlides
                naturalSlideWidth={20}
                naturalSlideHeight={slides.naturalSlideHeight}
                totalSlides={item[1].length}
                visibleSlides={slides.visibleSlides}
                style={{ position: `relative` }}
              >
                <StyledButtonBack>
                  <svg
                    width="8"
                    height="15"
                    viewBox="0 0 8 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 13.5L1 7.5L7 1.5"
                      stroke="#5858A6"
                      strokeWidth="2"
                      strokeLinejoin="round"
                    />
                  </svg>
                </StyledButtonBack>
                <StyledButtonNext>
                  <svg
                    width="8"
                    height="15"
                    viewBox="0 0 8 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.5L7 7.5L0.999998 13.5"
                      stroke="#5858A6"
                      strokeWidth="2"
                      strokeLinejoin="round"
                    />
                  </svg>
                </StyledButtonNext>
                <Slider>
                  {item[1].map((path) => (
                    <Slide>
                      <PathContainer key={path._id}>
                        <Link to={slugify(path.name).toLowerCase()}>
                          <div
                            style={{
                              display: `flex`,
                              flexDirection: `column`,
                            }}
                          >
                            <img
                              src={path.bannerSrc}
                              alt={path.name}
                              style={{ height: `170px`, objectFit: `cover` }}
                            />
                            <PathContent>
                              <Tags>
                                <PathTag>LEARNING PATH</PathTag>
                              </Tags>
                              <PathTitle>{path.name}</PathTitle>
                              <PathDescripption>
                                {path.abstract}
                              </PathDescripption>
                            </PathContent>
                            <PathFooter>
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="10"
                                  cy="10.6539"
                                  r="9.25"
                                  stroke="#8494B2"
                                  strokeWidth="1.5"
                                />
                                <path
                                  d="M10 5.13312V11.1748H15.5208"
                                  stroke="#8494B2"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              {path.duration}
                            </PathFooter>
                          </div>
                        </Link>
                      </PathContainer>
                    </Slide>
                  ))}
                </Slider>
              </CarouselProvider>
            </section>
          )
        }
      })}
    </StyledWrapper>
  )
}

export default PathsList
